import LinkButton from '@medefer/link-button';
import Section from '@medefer/section';
import React from 'react';
import { PCTEmail, PCTPhone } from '../constants/environmentVariables';
import toggleChatWidget from '../services/chat-bot-widget-service';
import './DeclineConfirmationView.css';

function DeclineConfirmationView() {
  return (
    <Section
      className="decline-confirmation-container mt-5"
      title="Your appointment has been
      cancelled, we will notify your
      consultant"
    >
      <span className="decline-confirmation-content">
        If this was a mistake or if you changed your mind please contact the Patient Care Team on:
        <div className="mt-5">
          Email:
          {' '}
          {PCTEmail}
        </div>
        <div className="mt-1">
          Phone:
          {' '}
          {PCTPhone}
        </div>
        <div className="mt-1">
          <LinkButton onClick={toggleChatWidget} label="Chat with us here" />
        </div>
      </span>
    </Section>
  );
}

export default DeclineConfirmationView;
