export const AppRoutes = {
  Default: '/',
  Home: 'booking/',
  Error: '/error',
  Login: '/login',
  AvailableSlots: '/booking/available-slots',
  AppointmentSummary: '/booking/appointment-summary',
  AppointmentConfirmation: '/booking/appointment-confirmation',
  DeclineAppointment: '/booking/decline-appointment',
  DeclineConfirmation: '/booking/decline-confirmation',
};

export default AppRoutes;
