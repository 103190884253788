export const BookingAPIResources = {
  AvailableSlots: 'PatientAppointment/GetPatientUserSlots',
  ExistingAppointment: 'PatientAppointment/GetPatientAppointment',
  SubmitAppointment: 'PatientAppointment/InsertPatientAppointment',
  RescheduleAppointment: 'PatientAppointment/ReschedulePatientAppointment',
  DeclineAppointment: 'PatientAppointment/CancelPatientAppointment',
};

export const BookingAPIHeaders = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  Authorization: '',
};
