import React, { useState } from 'react';
import PropTypes from 'prop-types';
import './BookingPatientInfo.css';
import Title from '@medefer/title';
import Slot from '@medefer/slot';
import './AvailableSlots.css';
import Alert, { AlertTypes } from '@medefer/alert';
import LinkButton from '@medefer/link-button';
import LoadingCircle, { CircleSizes } from '@medefer/loading-circle';
import { getFormattedDate } from '../utils/date-util';
import { PCTEmail, PCTPhone } from '../constants/environmentVariables';
import toggleChatWidget from '../services/chat-bot-widget-service';

function AvailableSlots({
  slots, startDate, onChange, isLoading,
}) {
  const maxCount = 12;
  const findInitialPageNumber = () => {
    if (slots && slots.length) {
      let slotIndex = slots.findIndex((slot) => slot.StartDate === startDate) + 1;
      if (slotIndex === -1) { slotIndex = 0; }
      const result = Math.ceil(slotIndex / maxCount);
      return result === 0 ? 1 : result;
    }

    return 1;
  };
  const [pageNumber, setPageNumber] = useState(findInitialPageNumber());

  const currentSlots = () => slots.filter(
    (slot, index) => (index >= maxCount * (pageNumber - 1))
      && (index < maxCount * pageNumber),
  );

  return (
    <>
      <div className="mb-3">
        <Title
          value="Select the next available slot for your appointment"
        />
      </div>
      {
        maxCount < slots.length
        && (
          <div className="mb-3 available-slot-pagination">
            <div className="available-slot-pagination-first-item">
              <LinkButton
                label="<< Previous"
                onClick={() => setPageNumber(pageNumber - 1)}
                disabled={pageNumber === 1}
              />
            </div>
            <div className="">
              <LinkButton
                label="Next >>"
                onClick={() => setPageNumber(pageNumber + 1)}
                disabled={pageNumber * maxCount >= slots.length}
              />
            </div>
          </div>
        )
      }
      {
        isLoading && (
          <div className="available-slots-loading-content">
            <LoadingCircle size={CircleSizes.Large} />
          </div>
        )
      }
      <div className="available-slot-grid">
        {
          !isLoading && slots.length === 0
            ? (
              <Alert type={AlertTypes.Warning}>
                <>
                  Sorry, there are no available slots. Please
                  {' '}
                  <LinkButton onClick={toggleChatWidget} label="chat with us here" />
                  {' '}
                  or contact the Patient Care Team on Email:
                  {' '}
                  {PCTEmail}
                  {' '}
                  or Phone:
                  {' '}
                  {PCTPhone}
                  .
                </>
              </Alert>
            )
            : currentSlots().map((slot) => (
              <Slot
                key={slot.StartDate}
                text={getFormattedDate(slot.StartDate)}
                checked={startDate && startDate === slot.StartDate}
                onChange={(checked) => (checked ? onChange(slot) : onChange(null))}
              />
            ))
        }
      </div>
    </>
  );
}

AvailableSlots.propTypes = {
  slots: PropTypes.instanceOf(Array),
  startDate: PropTypes.string,
  onChange: PropTypes.func,
  isLoading: PropTypes.bool,
};

AvailableSlots.defaultProps = {
  slots: [],
  onChange: undefined,
  startDate: undefined,
  isLoading: true,
};

export default AvailableSlots;
