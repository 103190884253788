import React, { useEffect } from 'react';
import './AvailableSlotsView.css';
import Alert, { AlertTypes } from '@medefer/alert';
import Button from '@medefer/button';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import LinkButton from '@medefer/link-button';
import BookingPatientInfo from '../components/BookingPatientInfo';
import AvailableSlots from '../components/AvailableSlots';
import {
  availableSlotState,
  getAvailableSlotsAsync,
  setSelectedSlot,
} from '../redux/reducers/booking-reducer';
import { AppRoutes } from '../constants/routing';
import toggleChatWidget from '../services/chat-bot-widget-service';
import { PCTEmail, PCTPhone } from '../constants/environmentVariables';

function AvailableSlotsView() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const availableSlot = useSelector(availableSlotState);

  useEffect(() => {
    dispatch(getAvailableSlotsAsync());
  }, []);

  const handleSlotChange = (slot) => {
    dispatch(setSelectedSlot(slot));
  };

  const handleOnContinue = async () => {
    navigate(AppRoutes.AppointmentSummary);
  };

  return (
    <div className="available-slots-container">
      <div className="mb-5">
        <BookingPatientInfo />
      </div>
      <div className="mb-5">
        <AvailableSlots
          isLoading={availableSlot.isLoading}
          slots={availableSlot.values}
          startDate={availableSlot.selectedSlot.StartDate}
          onChange={handleSlotChange}
        />
      </div>
      <div className="mb-5">
        {availableSlot.values && availableSlot.values.length > 0
          && (
            <Alert className="mb-3" type={AlertTypes.Default}>
              <>
                If you cannot find an appropriate appointment slot, please
                {' '}
                <LinkButton onClick={toggleChatWidget} label="chat with us here" />
                {' '}
                or contact the Patient Care Team to help you via Email:
                {' '}
                {PCTEmail}
                {' '}
                or Phone:
                {' '}
                {PCTPhone}
                .
              </>
            </Alert>
          )}
        <Button
          label="Continue"
          onClick={handleOnContinue}
          disabled={!availableSlot.selectedSlot.StartDate}
        />
      </div>
    </div>
  );
}

export default AvailableSlotsView;
