/* eslint-disable react/no-unstable-nested-components */
import React from 'react';
import {
  BrowserRouter,
  Route,
  Routes,
} from 'react-router-dom';
import { AppInsightsContext, AppInsightsErrorBoundary } from '@microsoft/applicationinsights-react-js';
import { AppRoutes } from './constants/routing';
import AvailableSlotsView from './views/AvailableSlotsView';
import AppointmentSummaryView from './views/AppointmentSummaryView';
import AppointmentConfirmationView from './views/AppointmentConfirmationView';
import ErrorView from './views/ErrorView';
import CheckExistingAppointmentView from './views/CheckExistingAppointmentView';
import CustomRoute from './components/routing/CustomRoute';
import DeclineAppointmentView from './views/DeclineAppointmentView';
import DeclineConfirmationView from './views/DeclineConfirmationView';
import loadAppInsights from './utils/appInsights';

const reactPlugin = loadAppInsights();

function App() {
  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      <AppInsightsErrorBoundary onError={() => <ErrorView />} appInsights={reactPlugin}>
        <BrowserRouter>
          <Routes>
            <Route exact path={AppRoutes.Error} element={<ErrorView />} />
            <Route exact path={AppRoutes.Login} element={<ErrorView />} />
            <Route
              exact
              path={AppRoutes.AvailableSlots}
              element={(
                <CustomRoute>
                  <AvailableSlotsView />
                </CustomRoute>
              )}
            />
            <Route
              exact
              path={AppRoutes.AppointmentSummary}
              element={(
                <CustomRoute>
                  <AppointmentSummaryView />
                </CustomRoute>
              )}
            />
            <Route
              exact
              path={AppRoutes.AppointmentConfirmation}
              element={(
                <CustomRoute>
                  <AppointmentConfirmationView />
                </CustomRoute>
              )}
            />
            <Route
              exact
              path={AppRoutes.DeclineAppointment}
              element={(
                <CustomRoute>
                  <DeclineAppointmentView />
                </CustomRoute>
              )}
            />
            <Route
              exact
              path={AppRoutes.DeclineConfirmation}
              element={(
                <CustomRoute>
                  <DeclineConfirmationView />
                </CustomRoute>
              )}
            />
            <Route path="/">
              <Route path={AppRoutes.Home} element={<CheckExistingAppointmentView />} />
              <Route path={AppRoutes.Default} element={<CheckExistingAppointmentView />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </AppInsightsErrorBoundary>
    </AppInsightsContext.Provider>
  );
}

export default App;
