import InputField from '@medefer/input-field';
import PropTypes from 'prop-types';
import Section, { SectionTypes } from '@medefer/section';
import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { patientInfoState, setPreferredMobile } from '../redux/reducers/booking-reducer';
import DisplayField from './controls/DisplayField';

function PatientDetailsSection({ disabled }) {
  const dispatch = useDispatch();
  const patientInfo = useSelector(patientInfoState);

  const handleOnPreferrenMobileChange = (val) => {
    dispatch(setPreferredMobile(val));
  };

  return (
    <div>
      <Section className="mb-5" title="Your details" type={SectionTypes.Info}>
        <>
          <DisplayField caption="Patient" value={patientInfo.patientName} />
          <DisplayField caption="NHS Number" value={patientInfo.nhsNumber} />
          <DisplayField caption="Mobile Number" value={patientInfo.mobile} />
          <DisplayField caption="E-mail" value={patientInfo.email} />
        </>
      </Section>
      <InputField
        disabled={disabled}
        id="preferredMobileInput"
        label="Preferred number for telephone appointment"
        required
        value={patientInfo.preferredMobile}
        onChange={(e) => handleOnPreferrenMobileChange(e.target.value)}
      />
    </div>
  );
}

PatientDetailsSection.propTypes = {
  disabled: PropTypes.bool,
};
PatientDetailsSection.defaultProps = {
  disabled: false,
};

export default PatientDetailsSection;
