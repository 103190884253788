import Section, { SectionTypes } from '@medefer/section';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { patientInfoState } from '../redux/reducers/booking-reducer';
import { getFormattedDate } from '../utils/date-util';
import './CurrentAppointmentSection.css';
import DisplayField from './controls/DisplayField';

function CurrentAppointmentSection({ title, type }) {
  const patientInfo = useSelector(patientInfoState);

  return (
    <Section
      className="current-appointment-section"
      title={title}
      type={type}
    >
      <>
        <DisplayField caption="Date & Time" value={getFormattedDate(patientInfo.selectedSlot.StartDate)} />
        <DisplayField caption="Duration" value="20 mins" />
        <DisplayField caption="Doctor" value={patientInfo.consultant || '-'} />
        <DisplayField caption="Speciality" value={patientInfo.speciality || '-'} />
      </>
    </Section>
  );
}

CurrentAppointmentSection.propTypes = {
  title: PropTypes.string,
  type: PropTypes.oneOf([SectionTypes.Default, SectionTypes.Info, SectionTypes.Danger]),
};

CurrentAppointmentSection.defaultProps = {
  title: null,
  type: SectionTypes.Info,
};

export default CurrentAppointmentSection;
