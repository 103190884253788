import { ApplicationInsights } from '@microsoft/applicationinsights-web';
import { ReactPlugin } from '@microsoft/applicationinsights-react-js';
import { createBrowserHistory } from 'history';
import { AppInsightConnectionString, AppInsightsKey } from '../constants/environmentVariables';

const reactPlugin = new ReactPlugin();

const loadAppInsights = () => {
  const browserHistory = createBrowserHistory({ basename: '' });
  const appInsights = new ApplicationInsights({
    config: {
      instrumentationKey: AppInsightsKey,
      connectionString: AppInsightConnectionString,
      enableAutoRouteTracking: true,
      enableCorsCorrelation: true,
      enableRequestHeaderTracking: true,
      enableResponseHeaderTracking: true,
      extensions: [reactPlugin],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
      },
    },
  });
  appInsights.loadAppInsights();
  appInsights.trackPageView();

  return [reactPlugin, appInsights];
};

export const getReactPlugin = () => reactPlugin;

export default loadAppInsights;
