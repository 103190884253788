import React from 'react';
import { Navigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { AppRoutes } from '../../constants/routing';
import {
  bookingState,
} from '../../redux/reducers/booking-reducer';
import { BookingStatus, SubmissionStatus } from '../../constants/appointmentConstants';

function CustomRoute({ children }) {
  const {
    existingAppointment,
    selectedSlot,
    submission,
    status,
  } = useSelector(bookingState);

  if (window.location.pathname === AppRoutes.AvailableSlots) {
    if ((status === BookingStatus.Confirmed && !!existingAppointment)
      || submission.status === SubmissionStatus.Submitted) {
      return <Navigate to={AppRoutes.AppointmentConfirmation} replace />;
    }
  }

  if (window.location.pathname === AppRoutes.AppointmentSummary) {
    if ((status === BookingStatus.Confirmed && !!existingAppointment)
      || submission.status === SubmissionStatus.Submitted) {
      return <Navigate to={AppRoutes.AppointmentConfirmation} replace />;
    }

    if (!selectedSlot || Object.keys(selectedSlot).length === 0) {
      return <Navigate to={AppRoutes.Default} replace />;
    }
  }

  if (window.location.pathname === AppRoutes.AppointmentConfirmation) {
    if (!selectedSlot || Object.keys(selectedSlot).length === 0) {
      return <Navigate to={AppRoutes.Default} replace />;
    }

    if (status === BookingStatus.Confirmed && !existingAppointment && submission.status !== SubmissionStatus.Submitted) {
      return <Navigate to={AppRoutes.AppointmentSummary} replace />;
    }
  }

  return children;
}

CustomRoute.propTypes = {
  children: PropTypes.node.isRequired,
};

export default CustomRoute;
