import React, { useEffect } from 'react';
import './AppointmentSummaryView.css';
import Button from '@medefer/button';
import LinkButton from '@medefer/link-button';
import { useNavigate } from 'react-router-dom';
import Section, { SectionTypes } from '@medefer/section';
import Title from '@medefer/title';
import Alert, { AlertTypes } from '@medefer/alert';
import { useDispatch, useSelector } from 'react-redux';
import CurrentAppointmentSection from '../components/CurrentAppointmentSection';
import PatientDetailsSection from '../components/PatientDetailsSection';
import {
  bookingState,
  initializeSummaryViewState,
  selectedSlotState, submissionState, submitAppointmentAsync,
} from '../redux/reducers/booking-reducer';
import { AppRoutes } from '../constants/routing';
import { BookingStatus, SubmissionStatus } from '../constants/appointmentConstants';
import toggleChatWidget from '../services/chat-bot-widget-service';
import { PCTEmail, PCTPhone } from '../constants/environmentVariables';

function AppointmentSummaryView() {
  const dispatch = useDispatch();
  const { status, existingAppointment } = useSelector(bookingState);
  const submissionResult = useSelector(submissionState);
  const selectedSlot = useSelector(selectedSlotState);
  const navigate = useNavigate();

  const handleOnContinue = async () => {
    const oldPatientBookingId = status === BookingStatus.Rescheduling
      ? existingAppointment.PatientBookingID
      : null;
    dispatch(submitAppointmentAsync({ ...selectedSlot, PatientBookingID: oldPatientBookingId }));
  };

  useEffect(() => {
    dispatch(initializeSummaryViewState());
  }, []);

  useEffect(() => {
    if (submissionResult.status === SubmissionStatus.Submitted) {
      navigate(AppRoutes.AppointmentConfirmation);
    }
  }, [submissionResult]);

  return (
    <div className="appointment-summary-container">
      <div className="mb-5">
        <LinkButton className="appointment-summary-back-btn" onClick={() => navigate(AppRoutes.AvailableSlots)} label="Go back" />
      </div>

      <div className="summary-info-container">
        <div className="summary-info-item mb-5">
          <CurrentAppointmentSection title="Summary of your appointment" />
        </div>
        <div className="summary-info-item mb-5">
          <PatientDetailsSection disabled={submissionResult.status === SubmissionStatus.Submitting} />
        </div>
      </div>

      <div className="">
        <div className="mb-5">
          {(submissionResult.error
            && (
              <Alert
                type={AlertTypes.Warning}
                value={submissionResult.error}
                className="mb-5"
              />
            ))}
          <Button
            loading={submissionResult.status === SubmissionStatus.Submitting}
            disabled={submissionResult.status === SubmissionStatus.Submitting || !selectedSlot.MobileTelephone}
            className="appointment-summary-confirm-btn"
            label="Confirm Appointment"
            onClick={handleOnContinue}
          />
        </div>

        <Section
          className="mb-5"
          type={SectionTypes.Info}
        >
          <>
            <div className="mb-1">
              <Title value="Why can I not confirm my appointment?" />
            </div>
            <span>
              Please ensure there is a contact telephone number to confirm your appointment.
            </span>
          </>
        </Section>
        <Section
          className="mb-5"
          type={SectionTypes.Info}
        >
          <>
            <div className="mb-1">
              <Title value="Can I reschedule my appointment?" />
            </div>
            <span>
              We can reschedule your appointment by
              {' '}
              <LinkButton onClick={toggleChatWidget} label="chatting with us here" />
              {' '}
              or contacting the Patient Care Team on
              {' '}
              {PCTPhone}
              {' '}
              or
              {' '}
              {PCTEmail}
            </span>
          </>
        </Section>
      </div>

    </div>
  );
}

export default AppointmentSummaryView;
