import React from 'react';
import './ErrorView.css';

function ErrorView() {
  return (
    <div data-testid="errorView" className="ErrorView-container">
      <h1>!</h1>
      <h2>Sorry, we are unable to process your request at the moment.</h2>
      <h3>Patient Booking</h3>
      <span>Please try again later.</span>
    </div>
  );
}

export default ErrorView;
