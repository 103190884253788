import React from 'react';
import PropTypes from 'prop-types';
import './DisplayField.css';

function DisplayField({ caption, value }) {
  return (
    <div className="display-field">
      <div className="display-field-caption">
        {caption}
      </div>
      <div>
        {value}
      </div>
    </div>
  );
}

DisplayField.propTypes = {
  caption: PropTypes.string.isRequired,
  value: PropTypes.string,
};

DisplayField.defaultProps = {
  value: '-',
};

export default DisplayField;
