import { BookingAPIResources } from '../constants/apiConstants';
import * as bookingClient from './clients/booking-client';

const sortAvailableSlots = (data) => {
  if (data) {
    return [...data].sort((a, b) => new Date(a.StartDate) - new Date(b.StartDate));
  }
  return [];
};

export const fetchAvailableSlots = async () => {
  const payload = await bookingClient.get(BookingAPIResources.AvailableSlots);
  return {
    data: sortAvailableSlots(payload.data),
    error: payload.error,
  };
};

export const fetchExistingAppointment = async () => {
  const payload = await bookingClient.get(BookingAPIResources.ExistingAppointment);
  return {
    data: payload.data,
    error: payload.error,
  };
};

export const submitAppointment = async (body) => {
  const payload = await bookingClient.post(BookingAPIResources.SubmitAppointment, body);
  return {
    data: payload.data,
    error: payload.error,
  };
};

export const rescheduleAppointment = async (body) => {
  const payload = await bookingClient.post(BookingAPIResources.RescheduleAppointment, body);
  return {
    data: payload.data,
    error: payload.error,
  };
};

export const declineAppointment = async (body) => {
  const payload = await bookingClient.post(BookingAPIResources.DeclineAppointment, body);
  return {
    data: payload.data,
    error: payload.error,
  };
};
