export const SessionKeys = {
  UserInfo: 'User',
  UserKey: 'UserKey',
};

export const clearSession = () => {
  localStorage.removeItem(SessionKeys.UserInfo);
};

export const getUserKey = () => localStorage.getItem(SessionKeys.UserKey);

export const getUser = () => {
  const user = localStorage.getItem(SessionKeys.UserInfo);
  if (user) { return JSON.parse(user); }

  return {};
};

export const getUserToken = () => {
  const user = localStorage.getItem(SessionKeys.UserInfo);
  if (user) {
    return JSON.parse(user).Token;
  }

  return null;
};

export const isUserAuthenticated = () => {
  const user = JSON.parse(localStorage.getItem(SessionKeys.UserInfo));
  if (user && user.IsSuccess) { return true; }

  return false;
};
