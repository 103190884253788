import React, { useEffect } from 'react';
import './AvailableSlotsView.css';
import { useDispatch, useSelector } from 'react-redux';
import { Navigate } from 'react-router-dom';
import LoadingCircle, { CircleSizes } from '@medefer/loading-circle';
import {
  getExistingAppointmentAsync,
  bookingState,
} from '../redux/reducers/booking-reducer';
import { AppRoutes } from '../constants/routing';

function CheckExistingAppointmentView() {
  const dispatch = useDispatch();
  const { existingAppointmentChecked, existingAppointment } = useSelector(bookingState);

  useEffect(() => {
    dispatch(getExistingAppointmentAsync());
  }, []);

  if (existingAppointmentChecked) {
    if (existingAppointment) {
      return <Navigate to={AppRoutes.AppointmentConfirmation} replace />;
    }

    return <Navigate to={AppRoutes.AvailableSlots} replace />;
  }

  return (
    <div className="check-existing-appointment-view">
      <LoadingCircle size={CircleSizes.Large} />
    </div>
  );
}

export default CheckExistingAppointmentView;
