/* eslint-disable no-param-reassign */
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import * as bookingApi from '../../api/booking-api';
import { SubmissionStatus, BookingStatus, DecliningStatus } from '../../constants/appointmentConstants';
import { getUser } from '../../services/authorization-service';
import submitAppointment from '../../services/booking-service';

const initialState = {
  availableSlots: [],
  selectedSlot: {},
  isLoading: false,
  error: undefined,
  existingAppointment: undefined,
  existingAppointmentChecked: false,
  submission: {
    status: SubmissionStatus.Default,
    error: undefined,
  },
  decline: {
    status: DecliningStatus.Default,
    error: undefined,
  },
  status: BookingStatus.New,
};

export const getAvailableSlotsAsync = createAsyncThunk(
  'booking/fetchAvailableSlots',
  async () => {
    const response = await bookingApi.fetchAvailableSlots();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const getExistingAppointmentAsync = createAsyncThunk(
  'booking/fetchExistingAppointment',
  async () => {
    const response = await bookingApi.fetchExistingAppointment();
    // The value we return becomes the `fulfilled` action payload
    return response.data;
  },
);

export const submitAppointmentAsync = createAsyncThunk(
  'booking/submitAppointment',
  async (data) => submitAppointment(data),
);

export const declineAppointmentAsync = createAsyncThunk(
  'booking/declineAppointment',
  async (reason, { getState }) => {
    const state = getState().booking;
    if (!reason || reason.length < 5) {
      return { error: 'Please check the decline reason.' };
    }

    const response = await bookingApi.declineAppointment({
      startDate: state.existingAppointment.StartDate,
      endDate: state.existingAppointment.EndDate,
      userAvailabilityId: state.existingAppointment.AvailBookingID,
      bookingId: state.existingAppointment.PatientBookingID,
      remarks: reason,
    });

    return response.data;
  },
);

export const bookingSlice = createSlice({
  name: 'booking',
  initialState,
  // The `reducers` field lets us define reducers and generate associated actions
  reducers: {

    setSelectedSlot: (state, action) => ({
      ...state,
      selectedSlot: {
        ...state.selectedSlot,
        ...action.payload,
        MobileTelephone: (action.payload.MobileTelephone === null || action.payload.MobileTelephone === undefined)
          ? getUser().MobileNumber
          : action.payload.MobileTelephone,
      },
    }),

    setPreferredMobile: (state, action) => ({
      ...state,
      selectedSlot: {
        ...state.selectedSlot,
        MobileTelephone: action.payload,
      },
    }),

    initializeSummaryViewState: (state) => ({
      ...state,
      submission: {
        status: SubmissionStatus.Default,
        error: undefined,
      },
    }),

    startRescheduling: (state) => ({
      ...state,
      status: BookingStatus.Rescheduling,
      submission: initialState.submission,
    }),
    resetSelectedSlot: (state) => ({
      ...state,
      selectedSlot: state.existingAppointment || initialState.selectedSlot,
    }),
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAvailableSlotsAsync.pending, (state) => ({
        ...state,
        availableSlots: initialState.availableSlots,
        error: undefined,
        isLoading: true,
      }))
      .addCase(getAvailableSlotsAsync.fulfilled, (state, action) => ({
        ...state,
        availableSlots: action.payload,
        isLoading: false,
      }))
      .addCase(getAvailableSlotsAsync.rejected, (state, action) => ({
        ...state,
        availableSlots: initialState.availableSlots,
        error: action.error.message,
        isLoading: false,
      }))

      .addCase(getExistingAppointmentAsync.pending, (state) => ({
        ...state,
        ...initialState,
        isLoading: true,
      }))
      .addCase(getExistingAppointmentAsync.fulfilled, (state, action) => ({
        ...state,
        existingAppointment: action.payload || initialState.existingAppointment,
        existingAppointmentChecked: true,
        submission: initialState.submission,
        selectedSlot: action.payload || initialState.selectedSlot,
        isLoading: false,
        status: (action.payload ? BookingStatus.Confirmed : BookingStatus.New),
      }))
      .addCase(getExistingAppointmentAsync.rejected, (state, action) => ({
        ...state,
        ...initialState,
        error: action.error.message,
      }))

      .addCase(submitAppointmentAsync.pending, (state) => ({
        ...state,
        submission: {
          status: SubmissionStatus.Submitting,
          error: undefined,
        },
      }))
      .addCase(submitAppointmentAsync.fulfilled, (state, action) => {
        const { error } = action.payload;

        return {
          ...state,
          submission: {
            status: (!error ? SubmissionStatus.Submitted : SubmissionStatus.Failed),
            error,
          },
          status: (!error ? BookingStatus.Confirmed : state.status),
          existingAppointment: action.payload.appointment,
          existingAppointmentChecked: true,
        };
      })
      .addCase(submitAppointmentAsync.rejected, (state, action) => ({
        ...state,
        submission: {
          status: SubmissionStatus.Failed,
          error: action.error.message,
        },
      }))

      .addCase(declineAppointmentAsync.pending, (state) => ({
        ...state,
        decline: {
          status: DecliningStatus.Submitting,
          error: undefined,
        },
      }))
      .addCase(declineAppointmentAsync.fulfilled, (state, action) => {
        let error;
        if (action.payload.id === 0) {
          error = 'An error happened. Please try it later or contact with PCT team.';
        }

        if (action.payload.error) { error = action.payload.error; }

        return {
          ...state,
          ...initialState,
          decline: {
            status: (!error ? DecliningStatus.Submitted : DecliningStatus.Failed),
            error,
          },
          status: (!error ? BookingStatus.Declined : state.status),
        };
      })
      .addCase(declineAppointmentAsync.rejected, (state, action) => ({
        ...state,
        decline: {
          status: DecliningStatus.Failed,
          error: action.error.message,
        },
      }));
  },
});

export const {
  setSelectedSlot,
  setPreferredMobile,
  initializeSummaryViewState,
  startRescheduling,
  resetSelectedSlot,
} = bookingSlice.actions;

export const availableSlotState = (state) => ({
  isLoading: state.booking.isLoading,
  values: state.booking.availableSlots,
  selectedSlot: state.booking.selectedSlot,
});

export const selectedSlotState = (state) => state.booking.selectedSlot;
export const submissionState = (state) => state.booking.submission;
export const declineState = (state) => state.booking.decline;
export const bookingState = (state) => state.booking;

export const patientInfoState = (state) => ({
  patientName: getUser().UserName || undefined,
  nhsNumber: getUser().NHSNum || undefined,
  mobile: getUser().MobileNumber || undefined,
  email: getUser().EmailAddress || undefined,
  consultant: state.booking.selectedSlot.RegistrarName,
  speciality: getUser().Speciality || undefined,
  selectedSlot: state.booking.selectedSlot,
  preferredMobile: state.booking.selectedSlot.MobileTelephone,
});

export const existingAppointmentState = (state) => state.booking.existingAppointment;

export default bookingSlice.reducer;
