/* eslint-disable import/no-import-module-exports */
import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
// import monitorReducersEnhancer from './enhancers/monitor-enhancer';
// import loggerMiddleware from './logger-middleware';
import storage from 'redux-persist/lib/storage';
import {
  persistReducer,
  FLUSH,
  REHYDRATE,
  PAUSE,
  PERSIST,
  PURGE,
  REGISTER,
} from 'redux-persist';
import rootReducer from './reducers/root-reducer';

const persistConfig = {
  key: 'root',
  storage,
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default function configureAppStore(preloadedState) {
  const store = configureStore({
    reducer: persistedReducer,
    // middleware: [loggerMiddleware, ...getDefaultMiddleware()],
    preloadedState,
    // enhancers: [monitorReducersEnhancer],
    middleware: getDefaultMiddleware({
      serializableCheck: {
        ignoredActions: [FLUSH, REHYDRATE, PAUSE, PERSIST, PURGE, REGISTER],
      },
    }),
  });

  if (process.env.NODE_ENV !== 'production' && module.hot) {
    module.hot.accept('./reducers/root-reducer', () => store.replaceReducer(rootReducer));
  }

  return store;
}
