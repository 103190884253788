import React, { useState } from 'react';
import './BookingPatientInfo.css';
import Section, { SectionTypes } from '@medefer/section';
import LinkButton from '@medefer/link-button';
import Alert, { AlertTypes } from '@medefer/alert';
import { useSelector } from 'react-redux';
import { patientInfoState } from '../redux/reducers/booking-reducer';
import toggleChatWidget from '../services/chat-bot-widget-service';
import { PCTEmail, PCTPhone } from '../constants/environmentVariables';

function BookingPatientInfo() {
  const [needTranslator, setNeedTranslator] = useState(false);
  const patientInfo = useSelector(patientInfoState);

  return (
    <Section
      className=""
      title="Your details"
      type={SectionTypes.Info}
    >
      <div className="patient-details">
        <span>{patientInfo.patientName}</span>
        <span>
          NHS No:
          {' '}
          {patientInfo.nhsNumber}
        </span>
        <span>
          Mobile Number:
          {' '}
          {patientInfo.mobile || '-'}
        </span>
        <span>
          Appointment duration: 20 mins
        </span>
        <LinkButton data-testid="123" label="I don&lsquo;t speak English" onClick={() => setNeedTranslator(!needTranslator)} />
        {needTranslator && (
          <Alert
            type={AlertTypes.Info}
          >
            <>
              We can arrange an interpreter for you, please
              {' '}
              <LinkButton onClick={toggleChatWidget} label="chat with us here" />
              {' '}
              or contact the Patient Care Team on
              {' '}
              {PCTPhone}
              {' '}
              or
              {' '}
              {PCTEmail}
            </>
          </Alert>
        )}
      </div>
    </Section>
  );
}

export default BookingPatientInfo;
