export const SubmissionStatus = {
  Default: 'default',
  Submitting: 'submitting',
  Submitted: 'submitted',
  Failed: 'failed',
};

export const BookingStatus = {
  New: 'new',
  Confirmed: 'confirmed',
  Rescheduling: 'rescheduling',
  Declined: 'declined',
};

export const DecliningStatus = {
  Default: 'default',
  Submitting: 'submitting',
  Submitted: 'submitted',
  Failed: 'failed',
};
