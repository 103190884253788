import { SeverityLevel } from '@microsoft/applicationinsights-web';
import { BookingAPIUrl } from '../../constants/environmentVariables';
import { getReactPlugin } from '../../utils/appInsights';
import LogScope from '../../constants/logConstants';
import { BookingAPIHeaders } from '../../constants/apiConstants';
import { clearSession, getUserToken } from '../../services/authorization-service';
import { AppRoutes } from '../../constants/routing';

const makeApiCall = async (resource, method, bodyData = null) => {
  const payload = {
    data: undefined,
    error: undefined,
  };
  const appInsights = getReactPlugin();
  const url = `${BookingAPIUrl}/api/${resource}`;
  const headers = BookingAPIHeaders;
  headers.Authorization = `Bearer ${getUserToken()}`;

  const reqOptions = {
    method,
    mode: 'cors',
    headers,
  };
  if (bodyData) {
    reqOptions.body = JSON.stringify(bodyData);
  }

  appInsights.trackTrace({
    message: `${LogScope}: booking-client ${method} started: Request to ${url}`,
    severityLevel: SeverityLevel.Information,
  }, {
    requestHeader: headers,
    requestBody: reqOptions.body,
  });

  try {
    const response = await fetch(url, reqOptions);

    if (response.status === 401) {
      /* istanbul ignore next */
      appInsights.trackTrace({
        message: `${LogScope}: Request to ${url} failed with status code: ${response.status}`,
        severityLevel: SeverityLevel.Warning,
      }, {
        requestHeader: headers,
        requestBody: reqOptions.body,
        response: JSON.stringify(response),
      });

      clearSession();
      window.location.replace(AppRoutes.Login);
      return payload;
    }
    if (response.status > 400) {
      /* istanbul ignore next */
      appInsights.trackTrace({
        message: `${LogScope}: Request to ${url} failed with status code: ${response.status}`,
        severityLevel: SeverityLevel.Warning,
      }, {
        requestHeader: headers,
        requestBody: reqOptions.body,
        response: JSON.stringify(response),
      });

      payload.error = `Request failed! Error: ${response.statusText}`;
      return payload;
    }

    const data = await response.json();
    /* istanbul ignore next */
    if (data && (data.error || response.ok === false)) {
      /* istanbul ignore next */
      appInsights.trackTrace({
        message: `${LogScope}: Request to ${url} failed with status code: ${response.status}`,
        severityLevel: SeverityLevel.Warning,
      }, {
        requestHeader: headers,
        requestBody: reqOptions.body,
        response: JSON.stringify(data),
      });
      /* istanbul ignore next */
      payload.error = data.Error;
    } else {
      payload.data = data;
    }
  } catch (error) {
    appInsights.trackTrace({ message: `${LogScope}: ${error.message}`, severityLevel: SeverityLevel.Critical }, { errorDetails: error });
  } finally {
    appInsights.trackTrace({ message: `${LogScope}: booking-client POST completed: Request to ${url}`, severityLevel: SeverityLevel.Information });
  }

  return payload;
};

export const get = async (resource) => Promise.resolve(makeApiCall(resource, 'GET'));

export const post = async (resource, bodyData) => Promise.resolve(makeApiCall(resource, 'POST', bodyData));
