import Alert, { AlertTypes } from '@medefer/alert';
import Button, { ButtonTypes } from '@medefer/button';
import Section, { SectionTypes } from '@medefer/section';
import Modal, { ModalSizes } from '@medefer/modal';
import TextareaField from '@medefer/textarea-field';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import CurrentAppointmentSection from '../components/CurrentAppointmentSection';
import { DecliningStatus } from '../constants/appointmentConstants';
import { AppRoutes } from '../constants/routing';
import {
  declineAppointmentAsync, declineState, resetSelectedSlot, startRescheduling,
} from '../redux/reducers/booking-reducer';
import './DeclineAppointmentView.css';

const initialState = {
  reason: '',
  confirmed: false,
  showModal: false,
};

function DeclineAppointmentView() {
  const [state, setState] = useState(initialState);
  const submissionResult = useSelector(declineState);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  React.useEffect(() => {
    dispatch(resetSelectedSlot());
  }, []);

  useEffect(() => {
    if (submissionResult.status === DecliningStatus.Submitted) {
      navigate(AppRoutes.DeclineConfirmation);
    }
  }, [submissionResult]);

  const closeConfirmationModal = () => setState({ ...state, showModal: false });

  const handleRescheduling = () => {
    dispatch(startRescheduling());
    navigate(AppRoutes.AvailableSlots);
  };

  const handleKeepAppointment = () => {
    navigate(AppRoutes.AppointmentConfirmation);
  };

  const handleOnDecline = () => {
    setState({ ...state, showModal: true });
  };

  const handleOnConfirmDecline = async () => {
    dispatch(declineAppointmentAsync(state.reason));
  };

  return (
    <div className="decline-view-container">

      <div className="decline-view-row mb-5">

        <Section
          type={SectionTypes.Danger}
          title="Decline telephone appointment invitation"
          hideBar
        >
          <span>
            By declining the appointment you confirm that you don’t need it and your case will be referred back to your Medefer clinician.
          </span>
        </Section>
      </div>

      <div className="decline-view-row">
        <div className="decline-info-item  mb-5 mr-1">
          <Button
            type={ButtonTypes.Primary}
            label="Reschedule appointment"
            onClick={handleRescheduling}
          />
        </div>
        <div className="decline-info-item  mb-5">
          <Button
            type={ButtonTypes.Blue}
            label="Keep appointment"
            onClick={handleKeepAppointment}
          />
        </div>
      </div>

      <div className="decline-view-row">

        <div className="decline-info-item  mb-5">
          <CurrentAppointmentSection type={SectionTypes.Danger} />
        </div>
        <div className="decline-info-item  mb-5">
          <TextareaField
            id="declineReasonTextarea"
            required
            rows={4}
            label="Reason for declining?"
            onChange={(e) => setState({ ...state, reason: e.target.value.trim() })}
            placeholder="Must be over 5 characters."
          >
            {state.reason}
          </TextareaField>
        </div>
      </div>

      <div className="decline-view-row">

        <div className="decline-info-item  mb-5">
          {(submissionResult.error
            && (
              <Alert
                type={AlertTypes.Warning}
                value={submissionResult.error}
                className="mb-5"
              />
            ))}
          <Button
            disabled={submissionResult.status === DecliningStatus.Submitting || !state.reason || state.reason.length < 5}
            type={ButtonTypes.Secondary}
            label="Decline appointment invitation"
            onClick={handleOnDecline}
          />

          <Modal
            title="Decline Confirmation"
            size={ModalSizes.Medium}
            show={state.showModal}
            onClose={closeConfirmationModal}
            body={(
              <div>
                Could you please confirm that you wanted to decline your appointment invitation?
              </div>
            )}
            footer={(
              <>
                <Button
                  disabled={submissionResult.status === DecliningStatus.Submitting}
                  type={ButtonTypes.Secondary}
                  onClick={closeConfirmationModal}
                  label="Cancel"
                />

                <Button
                  loading={submissionResult.status === DecliningStatus.Submitting}
                  type={ButtonTypes.Primary}
                  onClick={handleOnConfirmDecline}
                  label="Confirm"
                />
              </>
            )}
          />
        </div>

        <div className="decline-info-item  mb-5" />
      </div>

    </div>
  );
}

export default DeclineAppointmentView;
