import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AppointmentConfirmationView.css';
import Section, { SectionTypes } from '@medefer/section';
import Title from '@medefer/title';
import LinkButton from '@medefer/link-button';
import Button, { ButtonTypes } from '@medefer/button';
import { useDispatch } from 'react-redux';
import CurrentAppointmentSection from '../components/CurrentAppointmentSection';
import PatientDetailsSection from '../components/PatientDetailsSection';
import toggleChatWidget from '../services/chat-bot-widget-service';
import { PCTEmail, PCTPhone } from '../constants/environmentVariables';
import { resetSelectedSlot, startRescheduling } from '../redux/reducers/booking-reducer';
import { AppRoutes } from '../constants/routing';

function AppointmentConfirmationView() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const handleRescheduling = () => {
    dispatch(startRescheduling());
    navigate(AppRoutes.AvailableSlots);
  };

  const handleOnDecline = () => {
    navigate(AppRoutes.DeclineAppointment);
  };

  React.useEffect(() => {
    dispatch(resetSelectedSlot());
  }, []);

  return (
    <div className="confirmation-container">

      <div className="confirmation-info-container">
        <div className="confirmation-info-item mb-5">
          <CurrentAppointmentSection title="Your telephone appointment is confirmed" />
        </div>
        <div className="confirmation-info-item mb-5">
          <PatientDetailsSection disabled />
        </div>
      </div>

      <div className="mb-5">
        <Section
          type={SectionTypes.Info}
        >
          <>
            <div className="mb-1">
              <Title value="What happens next?" />
            </div>
            <span>
              At your appointment time, a Medefer doctor will call you from a witheld number
              to your registered number, or your preferred number, if provided. Please be available
              20 minutes either side of this time. If you cannot make your appointment, please
              {' '}
              <LinkButton onClick={toggleChatWidget} label="chat with us here" />
              {' '}
              or contact the Patient Care Team on
              {' '}
              {PCTPhone}
              {' '}
              or
              {' '}
              {PCTEmail}

            </span>
          </>
        </Section>
      </div>

      <div className="confirmation-info-container">
        <div className="confirmation-info-item mb-5 mr-1">
          <Button
            type={ButtonTypes.Blue}
            label="Reschedule appointment"
            onClick={handleRescheduling}
          />
        </div>
        <div className="confirmation-info-item mb-5">
          <Button
            type={ButtonTypes.Secondary}
            label="Decline appointment invitation"
            onClick={handleOnDecline}
          />
        </div>
      </div>

      <div className="mb-5">
        <Section
          type={SectionTypes.Info}
        >
          <>
            <div className="mb-1">
              <Title value="Reschedule" />
            </div>
            <span>
              If you need to reschedule, please use the &quot;Reschedule appointment&quot; button to take you back
              to the appointment options list, or contact the Patient Care Team with as much notice as possible and we will be happy to assist.
            </span>
          </>
        </Section>
      </div>

      <div className="mb-5">
        <Section
          type={SectionTypes.Info}
        >
          <>
            <div className="mb-1">
              <Title value="Decline telephone appointment invitation" />
            </div>
            <span>
              If you do not wish to have a telephone appointment, you can decline the offer through the &quot;Decline appointment invitation&quot;
              button, or contact the Patient Care Team who can discuss next steps and notify your consultant.
            </span>
          </>
        </Section>
      </div>

    </div>
  );
}

export default AppointmentConfirmationView;
