import * as bookingApi from '../api/booking-api';

const SubmissionErrorCodes = {
  default: 'default',
  notBooked: 'AppointmentNotBooked',
  exists: 'AppointmentExists',
  notExists: 'AppointmentNotExists',
  rescheduleCompleted: 'AppointmentRescheduleCompleted',
};

const getSubmissionErrorMessage = (errorCode) => {
  if (!errorCode) return '';

  if (errorCode === SubmissionErrorCodes.notBooked) {
    return 'Unfortunately, your chosen appointment slot couldn\'t be booked. Please try again with another slot.';
  }
  if (errorCode === SubmissionErrorCodes.exists
    || errorCode === SubmissionErrorCodes.rescheduleCompleted) {
    // eslint-disable-next-line max-len
    return 'You already have an appointment booked. Please log out and log back in again, or check your phone / email for your appointment confirmation.';
  }
  if (errorCode === SubmissionErrorCodes.notExists) {
    return 'Unfortunately, your chosen appointment slot couldn\'t be found. Please try again with another slot.';
  }

  return 'Selected slot couldn\'t be found! Please try again with another slot.';
};

const submitAppointment = async (data) => {
  const result = {
    id: 0,
    error: '',
    appointment: {},
  };

  if (!data || !data.StartDate || !data.EndDate || !data.UserAvailabilityId || !data.MobileTelephone) {
    result.error = getSubmissionErrorMessage(SubmissionErrorCodes.default);
  }

  if (!result.error) {
    let submissionResponse = {
      PatientBookingId: 0,
      ErrorCode: '',
    };

    if (data.PatientBookingID) {
      submissionResponse = await bookingApi.rescheduleAppointment({
        startDate: data.StartDate,
        endDate: data.EndDate,
        userAvailabilityId: data.UserAvailabilityId,
        mobileNumber: data.MobileTelephone,
        oldPatientBookingId: data.PatientBookingID,
      });
    } else {
      submissionResponse = await bookingApi.submitAppointment({
        startDate: data.StartDate,
        endDate: data.EndDate,
        userAvailabilityId: data.UserAvailabilityId,
        mobileNumber: data.MobileTelephone,
      });
    }

    result.id = submissionResponse.data.PatientBookingId;
    result.error = getSubmissionErrorMessage(submissionResponse.data.ErrorCode);

    if (!result.error) {
      const existingAppointmentResponse = await bookingApi.fetchExistingAppointment();
      result.appointment = existingAppointmentResponse.data;
    }
  }

  return result;
};

export default submitAppointment;
