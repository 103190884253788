import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { persistStore } from 'redux-persist';
import App from './App';
import reportWebVitals from './reportWebVitals';
import configureStore from './redux/configure-store';

const store = configureStore();
const persistor = persistStore(store);

let root;

// eslint-disable-next-line no-unused-vars
window.mountBooking = (containerId, history) => {
  // window.history = history;
  root = ReactDOM.createRoot(document.getElementById(containerId));
  root.render(
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <App />
      </PersistGate>
    </Provider>,
  );
};

window.unmountBooking = (containerId) => {
  if (root && document.getElementById(containerId)) {
    root.unmount(document.getElementById(containerId));
  }
};

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
